<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="on_hidden"
        @change="(val) =>{$emit('update:is-add-new-user-sidebar-active', val)}"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Formulario de categorías
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit, invalid }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <input type="hidden" name="id" value="">

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="name"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre de categoría"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="userData.name"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                        #default="validationContext"
                        name="order"
                        rules="required"
                    >
                        <b-form-group
                            label="Orden"
                            label-for="order"
                        >
                            <b-form-input
                                id="order"
                                type="number"
                                v-model="userData.order"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="my-2">
                        <b-card-text class="mb-0">
                            Mostrar en iOS
                        </b-card-text>
                        <b-form-checkbox
                            v-model="userData.status"
                            class="custom-control-primary"
                            name="check-button"
                            switch
                        />
                    </div>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                            :disabled="invalid"
                        >
                            Guardar
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancelar
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCardText, BFormCheckbox
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BCardText,
        BFormCheckbox,

        // Form Validation
        ValidationProvider,
        ValidationObserver,

    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isAddNewUserSidebarActive',
        event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
        userSelected: {
            type: Object,
            required: false
        },
        addNewUser: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        addNewUser: function (newValue) {
            if (newValue) {
                this.userData = {
                    name: '',
                    order: 0,
                    status: true,
                }
            } else {
                this.userData = {
                    id: this.userSelected.id,
                    name: this.userSelected.name,
                    order: this.userSelected.order,
                    status: this.userSelected.status,
                }
            }
        },
        userSelected: function (newValue, oldValue) {
            if (newValue.id && oldValue.id && newValue.id.toString() !== oldValue.id.toString()) {
                this.userData = {
                    id: this.userSelected.id,
                    name: this.userSelected.name,
                    order: this.userSelected.order,
                    status: this.userSelected.status,
                }
            } else if (!newValue.id) {
                this.userData = {
                    name: '',
                    order: 0,
                    status: true,
                }
            }
        }
    },
    methods: {
        on_hidden() {
            if (this.addNewUser) {
                this.resetForm()
            }
        },
        onSubmit() {

            store.dispatch('app-categories/' + (this.addNewUser ? 'addCategory' : 'editCategory'), this.userData)
                .then(() => {
                    this.$emit('refetch-data')
                    this.$emit('update:is-add-new-user-sidebar-active', false)
                })

        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
        }
    },
    setup() {

        const blankUserData = {
            name: '',
            order: 0,
            status: true,
        }

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData))
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData)

        return {
            userData,
            refFormObserver,
            getValidationState,
            resetForm
        }
    },
}
</script>
