import {ref} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCategoriesList() {
    //Use toast
    const toast = useToast()

    const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        //{key: 'id'},
        //{key: 'first_name', label: 'Nombre'},
        {key: 'order', label: 'Orden'},
        {key: 'name', label: 'Nombre'},
        {key: 'status', label: 'Mostrar en iOS'},
        {key: 'actions', label: 'Acciones'},
    ]

    const refetchData = () => {
        refUserListTable.value.refresh()
    }

    const fetchUsers = (ctx, callback) => {

        store
            .dispatch('app-categories/fetchCategories')
            .then(response => {
                callback(response)
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        fetchUsers,
        tableColumns,
        refUserListTable,
        refetchData,
    }
}
