import firebase from "firebase/compat"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchCategories() {
            return new Promise(resolve => {
                firebase
                    .firestore()
                    .collection('categories')
                    //.where('order', '>=', 1)
                    .orderBy('order', 'asc')
                    .get()
                    .then(snapshot => {
                        let users = []
                        snapshot.docs.forEach(u => {
                            const user = u.data()
                            user.id = u.id

                            users.push(user)
                        })


                        resolve(users)
                    })
            })
        },
        addCategory(ctx, data) {
            return new Promise(resolve => {
                //data.category = firebase.firestore().doc('categories/' + data.category)

                firebase.firestore().collection('categories').add(data).then(() => {
                    resolve(true)
                })
            })
        },
        editCategory(ctx, data) {
            return new Promise(resolve => {
                //data.category = firebase.firestore().doc('categories/' + data.category)

                firebase.firestore().collection('categories').doc(data.id).set(data).then(() => {
                    resolve(true)
                })
            })
        }
    }
}
