<template>
    <div>

        <category-form-view
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            :add-new-user="addNewUser"
            :user-selected="userSelected"
            @refetch-data="refetchData"
        />

        <div class="m-2">

            <!-- Table Top -->
            <b-row>
                <!-- Search -->
                <b-col
                    cols="12"
                    md="12"
                >
                    <div class="d-flex align-items-center justify-content-end w-100" style="width: 100%;">
                        <!--<b-form-input
                          v-model="searchQuery"
                          class="d-inline-block mr-1"
                          placeholder="Search..."
                        />!-->
                        <b-button-group>
                            <b-button
                                variant="primary"
                                @click="addNew"
                            >
                                <span class="text-nowrap">Agregar categoría</span>
                            </b-button>
                        </b-button-group>

                    </div>
                </b-col>
            </b-row>

        </div>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0"
        >
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >

                <!-- Column: User -->
                <template #cell(name)="data">
                    {{ data.item.name }}
                </template>

                <template #cell(status)="data">
                    {{ data.item.status ? 'Si' : 'No' }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button-group>
                        <b-button variant="primary" @click="editCategory(data.item)">
                            <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                    </b-button-group>
                </template>

            </b-table>
        </b-card>
    </div>
</template>

<script>
import {BButton, BButtonGroup, BCard, BCol, BRow, BTable} from "bootstrap-vue"
import store from "@/store"
import {onUnmounted, ref} from "@vue/composition-api"
import useCategoriesList from "@/views/categories/useCategoriesList"
import categoriesStoreModule from "@/views/categories/categoriesStoreModule"
import CategoryFormView from "@/views/categories/CategoryFormView"

export default {
    name: "CategoriesView",
    components: {
        CategoryFormView,
        BRow,
        BCard,
        BTable,
        BButton,
        BButtonGroup,
        BCol,
    },
    methods: {
        numberFormat(number) {
            number = parseFloat(number)
            let val = (number / 1).toFixed(2)//.replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        addNew() {
            this.userSelected = {}
            this.addNewUser = true
            this.isAddNewUserSidebarActive = true
        },
        editCategory(category) {
            this.userSelected = category
            this.addNewUser = false
            this.isAddNewUserSidebarActive = true
        },
        deleteUser() {

            this.$swal({
                title: '¿Seguro que desea eliminar la categoría?',
                text: "Dejarán de salir los productos en la tienda",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    // console.log(result.value, user)
                }
            })
        }
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-categories'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, categoriesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const isAddNewUserSidebarActive = ref(false)

        const {
            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
        } = useCategoriesList()

        const addNewUser = ref(true)
        const userSelected = ref({})

        return {

            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
            addNewUser,
            userSelected
        }
    }
}
</script>

<style scoped>

</style>
